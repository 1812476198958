<template>
  <main class="sub">
    <div class="inner job-posting">
      <div class="tit">
        <h1>코인관리</h1>
        <p class="stit">&nbsp;</p>
      </div>
      <div class="sub-cont">
        <div class="h2-tit type01">
          <h2 class="mshow">코인상품</h2>
        </div>

        <div class="yk-coin-item-wrap">
          <ul class="th-list">
            <li>숙박업종</li>
            <li>실제 결제금액</li>
            <li>적립되는 코인</li>
          </ul>
          <ul class="td-list">
            <li>
              <div class="cell-item">
                <label>
                  <input type="radio" class="radio" name="opt" />
                  <span>옵션1</span>
                </label>
              </div>
              <div class="cell-item"><strong>30,000</strong>원</div>
              <div class="cell-item"><strong>30,000</strong>코인</div>
            </li>
            <li>
              <div class="cell-item">
                <label>
                  <input type="radio" class="radio" name="opt" />
                  <span>옵션2</span>
                </label>
              </div>
              <div class="cell-item"><strong>30,000</strong>원</div>
              <div class="cell-item"><strong>30,000</strong>코인</div>
            </li>
            <li>
              <div class="cell-item">
                <label>
                  <input type="radio" class="radio" name="opt" />
                  <span>옵션3</span>
                </label>
              </div>
              <div class="cell-item"><strong>30,000</strong>원</div>
              <div class="cell-item"><strong>30,000</strong>코인</div>
            </li>
          </ul>
        </div>

        <div class="h2-tit">
          <h2 class="mshow">결제정보</h2>
        </div>
        <div class="yk-register-form-wrap">
          <div class="form-rows-wrap mt0">
            <div class="label">결제금액</div>
            <div class="form-item-wrap">
              <div class="value">
                <strong>336,850</strong>
              </div>
            </div>
          </div>
          <div class="form-rows-wrap">
            <div class="label">결제방법</div>
            <div class="form-item-wrap">
              <label>
                <input type="radio" class="radio" name="radio" />
                <span>간편결제</span>
              </label>
              <label>
                <input type="radio" class="radio" name="radio" />
                <span>기타결제</span>
              </label>
            </div>
          </div>
          <div class="form-rows-wrap">
            <div class="label none">&nbsp;</div>
            <div class="form-item-wrap">
              <!-- Swiper -->
              <div id="card-slide" class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <a href="javascript:void(0)">
                      <div class="logos">
                        <img src="~assets/image/etc/etc-card-ss.svg" />
                      </div>
                      <div class="card-name">삼성카드</div>
                      <div class="card-name">2737383***12</div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)">
                      <div class="logos">
                        <img src="~assets/image/etc/etc-card-ss.svg" />
                      </div>
                      <div class="card-name">삼성카드</div>
                      <div class="card-name">2737383***12</div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)">
                      <div class="logos">
                        <img src="~assets/image/etc/etc-card-ss.svg" />
                      </div>
                      <div class="card-name">삼성카드</div>
                      <div class="card-name">2737383***12</div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)">
                      <div class="logos">
                        <img src="~assets/image/etc/etc-card-ss.svg" />
                      </div>
                      <div class="card-name">삼성카드</div>
                      <div class="card-name">2737383***12</div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)">
                      <div class="logos">
                        <img src="~assets/image/etc/etc-card-ss.svg" />
                      </div>
                      <div class="card-name">삼성카드</div>
                      <div class="card-name">2737383***12</div>
                    </a>
                  </div>
                  <div class="swiper-slide">&nbsp;</div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <a href="javascript:;" class="link right">
        <span>결제하기</span>
      </a>
    </div>
  </main>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'

export default {
  mounted() {
    new Swiper('#card-slide', {
      slidesPerView: '2',
      spaceBetween: 60,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        320: {
          slidesPerView: '1',
          spaceBetween: 60,
        },
        // when window width is >= 640px
        769: {
          slidesPerView: '2',
          spaceBetween: 60,
        },
      },
    })
  },
}
</script>

<style></style>
