<template>
  <section class="sub-container">
    <sub-nav-with-user-info></sub-nav-with-user-info>
    <charge-point></charge-point>
  </section>
</template>

<script>
import SubNavWithUserInfo from 'Components/common/SubNavWithUserInfo'
import ChargePoint from 'Components/point/ChargePoint'
export default {
  components: {
    SubNavWithUserInfo,
    ChargePoint,
  },
}
</script>

<style></style>
